    @import '../../styles/mixins/global.scss';
    .profile-page-section-alignment {
        padding: 120px 0;
        .grid {
            display: grid;
            grid-template-columns: 400px minmax(0, 1fr);
            gap: 0 100px;
            .grid-items {
                .input {
                    margin: 0 0 16px 0;
                }
                .profile-image-alignment {
                    display: flex;
                    margin: 0 0 45px 0;
                    div:last-child {
                        display: flex;
                        align-items: flex-end;
                        margin-left: 6px;
                        label {
                            background: #5B5B5E;
                            @include pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 10px;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
                        }
                    }
                    div+div {}
                    div {
                        img {
                            width: 203px;
                            height: 203px;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
    
    .courses-section {
        // padding: 101px 0 ;
        .page-titles {
            padding: 0 0 23px 0;
            display: block !important;
            h1 {
                @include heading-3;
                color: var(--colors-black-1);
                margin: 0 0 31px 0;
                @media(max-width: 991px) {
                    font-size: 15px;
                    margin-bottom: 0;
                }
                @media(max-width: 767px) {
                    margin-bottom: 0;
                }
                @media(max-width:767px) {
                    margin-left: -10px;
                }
                @media(max-width:600px) {
                    margin-left: -10px;
                }
            }
            p {
                text-align: center;
                max-width: 928px;
                @include text-sm;
                line-height: 20px;
                @include font-medium;
                color: var(--colors-black-3);
            }
            @media(max-width: 767px) {
                padding: 26px 0 20px 0;
                margin-top: 26px;
            }
        }
    }
    
    .lektion {
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        padding-bottom: 23px;
    }
    
    .title {
        text-align: center;
    }
    
    .accordion-item {
        background-color: #ccc;
        width: 500px;
        border-radius: 10px;
        .accordion-header {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            .accordion-iocn {
                i {
                    color: #333;
                    cursor: pointer;
                }
            }
        }
        .accordion-body {
            border-top: 1px solid #333;
            padding: 10px;
            .link-wrap {
                word-break: break-all;
            }
        }
    }
    
    .accordion {
        display: flex;
        justify-content: center;
    }
    
    .btnWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        // cursor: pointer;
        .update-btn {
            cursor: pointer;
        }
        button {
            cursor: pointer;
        }
    }