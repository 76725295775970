@import '../../styles/mixins/global.scss';
.home-section-alignment {
    column-gap: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    .search-input {
        width: 100%;
        max-width: 513px;
        position: relative;
        .search-icon-alignment {
            position: absolute;
            top: 50%;
            @include flex-center;
            transform: translateY(-50%);
            right: 50px;
        }
        input {
            width: 100%;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
            border-radius: 30px;
            height: 61px;
            border: none;
            padding: 0 20px;
            background-color: var(--bg-light);
            @include text-md;
            color: var(--colors-gray);
            @include font-medium;
            &:focus {
                outline: none;
            }
        }
         ::placeholder {
            @include text-md;
            @include font-medium;
            color: var(--colors-gray);
        }
        @media(max-width: 1024px) {
            max-width: 334px;
            margin: 0 auto;
        }
        @media(max-width: 1024px) {
            padding: 0 0 0;
        }
        @media(max-width: 767px) {
            display: none;
        }
    }
    .bellIcon {
        height: 50px;
        width: 50px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--colors-orange);
        cursor: pointer;
        position: relative;
        .badge {
            height: 20px;
            width: 20px;
            top: -10px;
            right: 2px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            height: 30px;
        }
    }
}

.no-data-found {
    margin-top: 30px;
}