@import "../../styles/mixins/global.scss";

.courses-section {
  // padding: 101px 0;

  .page-title {
    padding: 0 0 23px 0;
    h1 {
      @include heading-3;
      color: var(--colors-black-1);
      margin: 0 0;
      padding: 0;
    }
    p {
      max-width: 928px;
      @include text-sm;
      line-height: 20px;
      @include font-medium;
      color: var(--colors-black-3);
    }
  }
}

.lektion {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  padding-bottom: 23px;
}
.display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  padding: 10px 30px;
  background-color: var(--colors-orange);
  cursor: pointer;
  color: var(--colors-white);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
  border: none;
  font-size: 18px;
  line-height: 21px;
  font-family: var(--default-font-family);

  font-weight: var(--font-medium);
  border-radius: 999px;
}

.e-kanban .e-kanban-table .e-header-cells .e-header-text,.e-kanban .e-kanban-table .e-header-cells .e-item-count {
  color: #fff !important;
//   font-weight: 600 !important;
}
table.e-kanban-table.e-header-table {
  margin: 15px 0 15px 0 !important;
}
.e-kanban .e-kanban-table .e-header-cells{
    background: #5B5B5E !important;
}
.e-kanban {
    border-radius: 10px !important;
}

.e-dialog {
  height: 80vh;
  max-height: 80vh !important;
  background: red;
  
}