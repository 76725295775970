@import "../../styles/mixins/global.scss";

.courses-section {
  // padding: 101px 0;
  .gridss {
    @include layout-grid;
    grid-template-columns: repeat(1, 1fr);

    .grid-items {
      padding: 20px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
      border-radius: 10px;
      background-color: var(--colors-white);
      grid-column: span 3;
      margin-bottom: 20px;
      @include breakpoint("max-xl") {
        grid-column: span 4;
      }
      .video-player {
        width: 100%;
        background: rgba(1, 11, 29, 0.25);
        height: 180px;
      }
      button {
        padding: 10px 30px;
        background-color: var(--colors-orange);
        @include pointer;
        color: var(--colors-white);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        border: none;
        @include text-md;
        line-height: 20px;
        @include font-medium;
        border-radius: 999px;
      }
      p {
        @include text-xs;
        padding: 8px 0 0 0;
        line-height: 18px;
        margin: 0 0 15px 0;
        @include font-medium;
      }
    }
  }
  .page-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 23px 0;
    h1 {
      @include heading-3;
      color: var(--colors-black-1);
      margin: 0;
    }
    p {
      max-width: 928px;
      @include text-sm;
      line-height: 20px;
      @include font-medium;
      color: var(--colors-black-3);
    }
  }
}

.lektion {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  padding-bottom: 23px;
}
.counts {
  border: none;
  width: auto;

  height: 40px;
  line-height: 40px;

  cursor: pointer;
  background-color: var(--colors-orange);
  justify-content: center;
  border-radius: 10px;
  box-shadow: var(--s2);
  font-size: 18px;

  font-family: var(--default-font-family);
  font-weight: var(--font-normal);
  color: var(--colors-white);
}
.spce {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.btns {
  padding: 10px 30px;
  background-color: var(--colors-orange);
  cursor: pointer;
  color: var(--colors-white);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
  border: none;
  font-size: 18px;
  line-height: 21px;
  font-family: var(--default-font-family);

  font-weight: var(--font-medium);
  border-radius: 999px;
}
.flex {
  display: flex;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 21px;
  font-family: var(--default-font-family);
  font-weight: var(--font-medium);
}
.mr-3 {
  margin-right: 12px;
}
.options {
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 21px;
  font-family: var(--default-font-family);
  font-weight: var(--font-medium);
}
.text-center{
  text-align: center;
}