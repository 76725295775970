@import "../../styles/mixins/global.scss";
.courses-details-section {
    padding: 60px 0;
    .video-grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 330px;
        gap: 0 20px;
        .video-grid-items {
            .white-dummy-box {
                height: 400px;
                width: 100%;
                background-color: var(--colors-white);
                box-shadow: var(-s2);
                border-radius: 4px;
            }
            .video-show-box {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: start;
                width: 90%;
                // padding-left: 0px;
                // padding-right: 54px;
                // height: 532px;
                // background: rgba(12, 71, 180, 0.25);
                @media(max-width: 1024px) {
                    width: 100%;
                    padding-right: calc(47px - 62px);
                }
            }
            .all-sub-text-alignment {
                padding-left: 30px;
                .file-text-alignment {
                    padding: 0 0 1rem 0;
                    div:first-child {
                        margin: 0 0 5px 0;
                    }
                    div {
                        p {
                            @include text-sm;
                            font-size: 13px;
                            line-height: normal;
                            color: #000;
                            margin: 0 0 13px 0;
                            a {
                                color: blue;
                                text-decoration: underline;
                            }
                        }
                        span {
                            @include text-sm;
                            color: #3d3d3d;
                        }
                    }
                }
                .first-texxt-alignment {
                    padding: 0 0 11px 0;
                    p {
                        @include text-sm;
                        color: #3d3d3d;
                        margin: 0;
                    }
                }
            }
            .video-sub-title-alignment {
                @include flex-center;
                padding: 0 0 30px 0;
                div {
                    @include flex-center;
                    height: 100%;
                    span {
                        font-size: 9px;
                        color: var(--colors-black-1);
                        display: block;
                        padding-left: 3px;
                    }
                }
            }
            .video-title-alignment {
                @include flex-center;
                padding: 20px 0 20px 0;
                div:first-child {
                    padding-right: 10px;
                }
                div {
                    @include flex-center;
                }
                div+div {
                    svg {
                        @include pointer;
                    }
                }
                div {
                    p {
                        @include text-md;
                        color: var(--colors-black-1);
                        font-weight: 600;
                    }
                }
            }
        }
        @media(max-width: 1024px) {
            display: block;
        }
    }
    .text {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        margin-top: 250px;
    }
    .department-box {
        background-color: var(--colors-white);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        border-radius: 99px;
        position: relative;
        margin: 0 0 50px 0;
        button {
            padding: 20px 25px;
            @include text-md;
            color: var(--colors-black-1);
            font-weight: 600;
            border: none;
            transition: 0.3s ease-in-out;
            background-color: transparent;
            margin-right: 10px;
            border-radius: 9999px;
            @include pointer;
            transition: 0.3s ease-in-out;
            // &:hover {
            //     background-color: var(--colors-orange);
            //     color: var(--colors-white);
            // }
            @media(max-width: 1024px) {
                padding: 12px 16px;
                font-size: 14px;
            }
        }
        // button:first-child {
        //     background-color: var(--colors-orange);
        //     color: var(--colors-black-1);
        // }
    }
    .page-title-alignment {
        @include flex-between;
        padding: 0 0 42px 0;
        h1 {
            @include heading-3;
            color: var(--colors-black-1);
            margin: 0;
        }
        .icon-design {
            width: 40px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
            height: 40px;
            border-radius: 10px;
            background-color: var(--colors-orange);
            @include flex-center;
            justify-content: center;
            @include pointer;
        }
    }
}

.videobtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.videoContorl {
    width: 100%;
    padding-bottom: 29.96px;
    .videoprogressSection {
        position: relative;
        .videoprogressline {
            width: 100%;
            background-color: #010b1d;
            height: 2px;
        }
        .videoprogress {
            position: absolute;
            width: 100%;
            background-color: #dedede;
            height: 2px;
            top: 0;
            width: 210px;
        }
    }
    .timeingSection {
        margin-top: 15px;
        .timeing {
            color: #000;
            font-size: 9px;
            line-height: 10px;
            font-weight: 400;
            margin-top: 15px;
        }
    }
    .zoomSection {
        margin-top: 26px;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
    }
    .playSection {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
            margin-right: 10px;
            path {
                stroke: black;
                stroke-width: 1.5;
                stroke-miterlimit: 10%;
                stroke-linecap: round;
                stroke-linejoin: round;
            }
        }
        .prevIcon {
            svg {
                path {
                    stroke-width: 2;
                }
            }
        }
        .nextIcon {
            svg {
                path {
                    stroke-width: 2;
                }
            }
        }
    }
    .videoSection {
        margin-top: 100px;
    }
    .prevIcon {
        transform: rotate(180deg);
        margin-right: 10px;
    }
}

.flex-alignment {
    display: flex;
    justify-content: space-between;
    padding: 0 0 23px 0;
    @media(max-width: 767px) {
        align-items: center;
    }
}

.heading {
    display: flex;
    justify-content: flex-start;
    font-family: 'Asap', sans-serif;
    h1 {
        font-size: 25px;
        line-height: 30px;
        font-family: var(--default-font-family);
        font-weight: var(--font-medium);
        color: var(--colors-black-1);
        margin: 0 0 0;
        padding: 0;
        @media(max-width: 991px) {
            font-size: 14px;
        }
    }
}

.backSection {
    // padding-left: 805px;
    padding: 0 0 23px 0;
    display: flex;
    justify-content: space-around;
    // align-items: center;
    .btns {
        padding: 10px 30px;
        background-color: var(--colors-orange);
        // cursor: pointer;
        color: var(--colors-white);
        box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
        border: none;
        font-size: 18px;
        line-height: 21px;
        font-family: var(--default-font-family);
        font-weight: var(--font-medium);
        border-radius: 999px;
    }
}

// .active {
//     background-color: var(--colors-orange) !important;
//     color: var(--colors-white) !important;
//     cursor: pointer !important;
// }

// .deactive {
//     background-color: var(--colors-white) !important;
//     color: var(--colors-white);
//     cursor: default !important;
// }

.playbtn {
    font-weight: bold !important;
}

.prevIcon {
    svg {
        path {
            stroke-width: 2;
        }
    }
}

.container-fluid-one {
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.next_arrow {
    position: absolute;
    right: 27px;
    top: 50%;
    cursor: pointer;
}

.downloadbtn {
    height: 46px;
    min-width: 220px;
    margin: 0 0 0 auto;
    padding: 0 20px;
    background-color: #ffffff;
    color: #5B5B5E;
    cursor: pointer;
    margin-bottom: 12px;
    box-shadow: 0px 4px 4px rgba(153, 149, 149, 0.16);
    border: none;
    font-size: 18px;
    line-height: 21px;
    font-family: var(--default-font-family);
    font-weight: var(--font-normal);
    line-height: 20px;
    font-weight: var(--font-medium);
    border-radius: 23px;
    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: center;
    svg {
        path {
            fill: #5B5B5E;
        }
    }
    a {
        font-weight: 400;
        color: #5B5B5E;
    }
}

.disabled {
    color: #aaa7a7 !important;
}