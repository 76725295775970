// @use './mixins/breakpoint';
// @use './mixins/typography.scss';
// @use './mixins/columns.scss';
// @use './function/columns.scss';
// @use './layout/container.scss';
// @use './mixins/index.scss';


@import '../function/columns';
@import '../layout/container';
@import '../mixins/columns';
@import '../mixins/breakpoint';
@import '../mixins/index';
@import '../function/function';
@import '../mixins/typography';
@import '../theme/theme';
@import '../scss/variable';
@import '../scss/font';
@import '../scss/mixin';
@import '../scss/shadow';
@import '../scss/input';

:global(.e-kanban-dialog .e-kanban-form-wrapper table .e-label) {
    vertical-align: center !important;
}