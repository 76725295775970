.input {
    label {
        @include text-md;
        color: var(--colors-black);
        display: block;
        padding: 0 0 5px 0;
        @media(max-width: 576px){
            font-size: 16px;
          }
    }
    input {
        width: 100%;
        height: 60px;
        border-radius: 10px;
        border: 1px dashed var(--colors-black);
        @include text-md;
        background-color: transparent;
        color: var(--colors-black);
        padding: 0 20px;
        &:focus {
            outline: none;
        }

        @media(max-width: 576px){
            font-size: 16px;
            height: 36px;
          }
    }
    textarea {
        width: 100%;
        height: 120px;
        border-radius: 10px;
        border: 1px dashed var(--colors-black);
        @include text-md;
        background-color: transparent;
        color: var(--colors-black);
        padding:20px;
        &:focus {
            outline: none;
        }
    }
}