@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
body {
    /* font-family: 'Asap', sans-serif; */
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    background: #EFEFEF;
}

* {
    box-sizing: border-box;
}

p:last-child {
    margin: 0;
}

img {
    max-width: 100%;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

.red {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    background-color: rgb(211, 47, 47);
    border: 2px solid rgb(211, 47, 47);
    transition: all 200ms linear 0s;
}

.blue {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    background-color: rgb(33, 32, 50);
    border: 2px solid rgba(255, 255, 255, 0.2);
    transition: all 200ms linear 0s;
}

.button {
    color: #fff;
    padding: 10px 12px;
    border: none;
    font-weight: bold;
    margin-right: 12px;
}

.flexssss {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.MuiButton-containedPrimary {
    color: #fff !important;
    background: #212032 !important;
}

.positionabsoklute {
    position: absolute;
    bottom: 20px;
}

.postionrrelative {
    position: relative;
}

.default {}

.error {
    color: red;
}