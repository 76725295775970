@import "../../styles/mixins/global.scss";
.progressbar-container {
    margin-top: 10px;
    background: #efefef !important;
    border-radius: 10px !important;
}

.progressbar-progress {
    background: #5B5B5E !important;
    border-radius: 10px !important;
}

.recharts-wrapper {
    width: 100% !important;
}

.gird-imemss2 {
    // @media(max-width: 1440px){
    //   width: 96%;
    // }
    width: 100%;
    .recharts-surface {
        @media (max-width: 1440px) {
            width: 100%;
        }
    }
}

.courses-section {
    // padding: 101px 0;
    .grids {
        @include layout-grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        .grid-itemss {
            position: relative;
            border-radius: 10px;
            background-color: #fff;
            padding: 30px;
            // overflow: auto;
            button {
                padding: 10px 30px;
                background-color: var(--colors-orange);
                @include pointer;
                color: var(--colors-white);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
                border: none;
                @include text-md;
                line-height: 20px;
                @include font-medium;
                border-radius: 999px;
            }
            h1 {
                @include heading-3;
                color: var(--colors-black-1);
                margin-bottom: 0px;
                padding-bottom: 0;
            }
            p {
                @include text-xs;
                padding: 8px 0 0 0;
                line-height: 18px;
                margin: 0 0 15px 0;
                @include font-medium;
            }
        }
        .grid-itemss1 {
            // width: 574px;
            margin-right: 30.34px;
            @media(max-width: 1280px) {
                margin-right: 0;
            }
        }
        .grid-itemss3 {
            .recharts-surface {
                width: 90%;
                @media(max-width: 1280px) {
                    width: 100%;
                }
            }
        }
    }
    .page-title2 {
        padding: 0 0 23px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
            @include heading-3;
            color: var(--colors-black-1);
            margin: 0 0 0;
            padding: 0;
        }
        p {
            max-width: 928px;
            @include text-sm;
            line-height: 20px;
            @include font-medium;
            color: var(--colors-black-3);
        }
    }
    @media(max-width: 767px) {
        padding-top: 26px;
        margin-top: 26px;
    }
}

.lektion {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding-bottom: 23px;
    @media(max-width: 991px) {
        font-size: 14px;
    }
}

.count {
    width: 40px;
    margin-right: 20px;
    height: 40px;
    line-height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--colors-orange);
    justify-content: center;
    border-radius: 10px;
    box-shadow: var(--s2);
    font-size: 18px;
    line-height: 21px;
    font-family: var(--default-font-family);
    font-weight: var(--font-normal);
    color: var(--colors-white);
}

.spce {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: large;
}

.mb-5 {
    margin-bottom: 20px;
}

.btns {
    padding: 10px 30px;
    background-color: var(--colors-orange);
    cursor: pointer;
    color: var(--colors-white);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
    border: none;
    font-size: 18px;
    line-height: 21px;
    font-family: var(--default-font-family);
    font-weight: var(--font-medium);
    border-radius: 999px;
    @media(max-width: 991px) {
        padding: 12px 16px;
        font-size: 14px;
    }
}

.rotate-text {
    font-size: large;
    transform: rotate(90deg);
}

// .cartsection{
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
// }
.recharts-wrapper {
    width: 90%;
}

.recharts-surface {
    @media (max-width: 1440px) {
        width: 80%;
    }
}

.grap {
    display: grid;
    // display: flex;
    grid-template-columns: repeat( auto-fit, minmax(460px, 1fr));
    gap: 30px;
    .grid-itemss1 {
        width: 100%;
        @media(max-width: 1280px) {
            width: auto;
        }
    }
    .recharts-surface {
        width: 90%;
        @media(max-width: 1280px) {
            width: 100%;
        }
    }
    @media(max-width: 1280px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.cartSection {
    position: relative;
    .cartLeft {
        position: absolute;
        top: 25%;
        transform: rotate(-180deg);
        writing-mode: vertical-lr;
    }
    .cartBottom {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.recharts-legend-wrapper {
    width: 100% !important;
}

.recharts-default-legend {
    position: absolute;
    bottom: 1px;
    left: 48%;
    transform: translateX(-35%);
}