@import "../../../styles/mixins/global.scss";
.slider-box-design {
  .slick-slide {
    padding: 0 0px;
  }
  .slick-track {
    margin: 0 -10px;
  }
  .grid-items {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: var(--colors-white);
    grid-column: span 3;
    @include breakpoint("max-xl") {
      grid-column: span 4;
    }
    .card-image {
      img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        height: 230px;
      }
    }
    .card-details {
      padding: 20px;

      h2 {
        @include text-md-bold;
        color: var(--colors-black-1);
        margin: 0 0 10px 0;
      }
      .rating-alignment {
        @include flex-center;
        padding-left: 10px;
        span {
          @include text-3xs;
          font-size: 9px;
          color: var(--colors-black-1);
        }
      }
      .icon-design {
        width: 40px;
        height: 40px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        background-color: var(--colors-orange);
        @include flex-center;
        justify-content: center;
        @include pointer;
        border-radius: 10px;
      }
      .icon-text-alignment {
        @include flex-between;
        .lesson {
          @include flex-center;
          p {
            @include text-2xs;
            color: var(--colors-black-1);
          }
        }
      }
    }
  }
}
.bellIcon{
  margin-top: 10px;
}
.textt {
  text-align: center;
  font-size: 18px;
  margin-top: 20%;
  line-height: 21px;
}

.Loading {
  display: flex;
  justify-content: center;
  margin-top: 20%;
  height: 100%;
  width: 100%;
}

.customer-support-section {
  .page-title {
    margin-top: 50px;
    padding: 0 0 map-get($pixel, sm) * 4 0;
    h1 {
      margin: 0;
      @include heading-3;
      color: var(--colors-black-1);
      @media (max-width: 991px) {
        font-size: 15px;
        padding-left: 12px;
      }
      @media (max-width: 767px) {
        font-size: 15px;
        margin-left: -27px;
      }
      @media (max-width: 767px) {
        font-size: 15px;
        margin-left: -20px;
      }
    }
  }
  &:first-child {
    margin-top: 0;
  }
  @media (max-width: 1024px) {
    margin-top: 57px;
    padding: 0px 0px 26px 0;
  }
}

.grid {
  @include layout-grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 40px;
  margin-bottom: 40px;
  .grid-items:last-child {
    @include breakpoint("max-xl") {
      // display: none;
    }
  }
  .grid-items {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: var(--colors-white);
    grid-column: span 3;
    @include breakpoint("max-xl") {
      grid-column: span 4;
    }
    .card-image {
      img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        height: 230px;
      }
    }
    .card-details {
      padding: 20px;
      height: 170px;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
      h2 {
        @include text-md-bold;
        color: var(--colors-black-1);
        margin: 0 0 10px 0;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
      .rating-alignment {
        @include flex-center;
        padding-left: 10px;
        span {
          @include text-3xs;
          font-size: 9px;
          color: var(--colors-black-1);
        }
      }
      .icon-design {
        width: 40px;
        height: 40px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        background-color: var(--colors-orange);
        @include flex-center;
        justify-content: center;
        @include pointer;
        border-radius: 10px;
      }
      .icon-text-alignment {
        @include flex-between;
        .lesson {
          @include flex-center;
          p {
            @include text-2xs;
            color: var(--colors-black-1);
          }
        }
      }
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(8, 1fr);
    gap: 31px;
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

.slider-box-design {
  .slick-track {
    width: 100% !important;
    margin: 0;
    .slick-slide {
      width: 100% !important;
    }
  }
}

.disabled {
  color: #afabab !important;
}
