@import "../../styles/mixins/global.scss";
.login-page-center-alignment {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    background-color: #ffff;
    .logo-wrapper {
        display: flex;
        justify-content: center;
        margin: 30px 0;
        .logo {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        @media(max-width: 576px){
            margin: 24px 0;
        }
    }
    .login-box {
        width: 628px;
        border: 1px solid #00affa;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 40px;
        background-color: #ffff;
        .input {
            margin: 0 0 30px 0;
        }
        .box-bottom-text {
            padding: 20px 0 0 0;
            p {
                @include text-md;
                color: #1c2435;
                text-align: center;
                margin: 0;
                @include pointer;
            }
        }
        .box-text {
            display: flex;
            justify-content: center;
            padding: 20px 0 0 0;
            p {
                @include text-md;
                color: #00affa;
                text-align: center;
                text-decoration: underline;
                text-decoration-color: #00affa;
                margin: 0;
                @include pointer;
            }
        }
        .login-button-center {
            // padding: 30px 100px 0 200px;
            justify-content: center !important;
            display: flex;
            button {
                padding: 8px 16px;
                background: #626265;
                border-radius: 9999px;
                @include heading-3;
                @include pointer;
                color: #fff;
                width: 65%;
                height: 68px;
                border: none;
                align-items: center;
                @media(max-width: 576px){
                    font-size: 18px;
                    height: 50px;
                    line-height: 22px;

                }
            }
        }

        @media(max-width: 767px){
            width: 100%;
            padding: 24px;
            

        }
    }
    .text-danger {
        color: red;
    }

    @media(max-width :767px){
        padding-left: 12px;
            padding-right: 12px;
    }
}

// logout modal
.modalBg {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.modalcontent {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modalbody {
        padding: 50px;
        min-width: 600px;
        background-color: #fff;
        border-radius: 5px;
    }
    .logouttext {
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
        font-size: 24px;
    }
    .logutbtnSection {
        display: flex;
        justify-content: center;
        margin-top: 35px;
    }
    .logutbtn {
        background-color: var(--colors-orange);
        border-radius: 5px;
        border: none;
        padding: 12px 28px;
        margin-right: 15px;
        color: #fff;
    }
}