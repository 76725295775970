@import '../../styles/mixins/global.scss';

.fortbildung-section {
    padding: 128px 0 80px 0;
    .fortbildung-box {
        width: 400px;
        .lektion-alignment {
            padding: 20px 0 0 0;
        }
        .sub-title {
            h2 {
                @include text-md-bold;
                color: var(--colors-black);
                margin: 0 0 16px 0;
            }
        }
        .records-alignment {
            padding: 0 0 36px 0;
            span {
                @include text-md;
                color: var(--colors-orange);
                display: block;
                @include pointer;
            }
        }
        button {
            background: rgba(242, 145, 0, 0.4);
            border-radius: 10px;
            @include flex-center;
            justify-content: center;
            @include heading-4;
            color: var(--colors-black);
            width: 100%;
            margin: 0 0 16px 0;
            @include pointer;
            line-height: 20px;
            height: 60px;
            border: none;
            span {
                padding-left: 24px;
            }
        }
        h1 {
            @include heading-3;
            margin: 0 0 23px 0;
            color: var(--colors-black-1);
            @include font-extra-bold;
        }
        .input {
            margin: 0 0 16px 0;
        }
        .more-lessons {
            button {
                background-color: var(--colors-orange);
                border-radius: 9999px;
                line-height: 20px;
                height: 40px;
                @include flex-center;
                justify-content: center;
                color: var(--colors-white);
                @include text-md-bold;
                @include font-medium;
                width: 200px;
                max-width: 200px;
            }
            span {
                padding-left: 10px;
            }
        }
    }

}