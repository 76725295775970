@mixin layout-grid {
    display: map-get($layout, grid);
}

@mixin pointer {
    cursor: map-get($layout, pointer );
}

@mixin block {
    display: map-get($layout, block );
}

@mixin flex-center {
    display: map-get($layout, flex );
    align-items: map-get($layout, center );
}

@mixin flex-between {
    @include flex-center();
    justify-content: space-between;
}

@mixin text-center {
    text-align: map-get($layout, center );
}

