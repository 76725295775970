@import '../../styles/mixins/global.scss';
.courses-section {
    padding: 101px 0;
    .grid {
        @include layout-grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 40px;
        .grid-items {
            box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
            border-radius: 10px;
            background-color: var(--colors-white);
            grid-column: span 3;
            @include breakpoint("max-xl") {
                grid-column: span 4;
            }
            .card-detail {
                padding: 20px;
                height: 180px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                h2 {
                    height: 130px;
                    padding-bottom: 0;
                }
                .btn-wrapper {
                    display: flex;
                    justify-content: flex-end;
                }
                @media(max-width: 1024px) {
                    padding: 15px;
                }
            }
            .video-player {
                width: 100%;
                background: rgba(1, 11, 29, 0.25);
                height: 230px;
            }
            button {
                padding: 10px 30px;
                background-color: var(--colors-orange);
                @include pointer;
                color: var(--colors-white);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
                border: none;
                @include text-md;
                line-height: 20px;
                @include font-medium;
                border-radius: 999px;
            }
            p {
                @include text-xs;
                padding: 8px 0 0 0;
                line-height: 18px;
                margin: 0 0 15px 0;
                @include font-medium;
            }
        }
        @media(max-width: 1024px) {
            grid-template-columns: repeat(8, 1fr) !important;
        }
        @media(max-width: 767px) {
            grid-template-columns: repeat(4, 1fr) !important;
        }
    }
    .textt {
        text-align: center;
    }
    .page-title {
        padding: 0 0 23px 0;
        h1 {
            @include heading-3;
            color: var(--colors-black-1);
            margin: 0 0 31px 0;
        }
        p {
            max-width: 928px;
            @include text-sm;
            line-height: 20px;
            @include font-medium;
            color: var(--colors-black-3);
        }
    }
}

.lektion {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding-bottom: 23px;
}

.card-image {
    img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        height: 230px;
        @media(max-width: 1024px) {
            height: 178px !important;
            color: var(--colors-white);
        }
    }
}