@mixin font-normal {
    font-weight: var(--font-normal);
}

@mixin font-medium {
    font-weight: var(--font-medium);
}

@mixin font-bold {
    font-weight: var(--font-bold);
}

@mixin font-extra-bold {
    font-weight: var(--font-extra-bold);
}