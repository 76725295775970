@use '../mixins/breakpoint.scss';

@mixin text-xs {
  font-size: 10px;
  line-height: 11px;
  font-family: var(--default-font-family);
  @include font-extra-bold;
}

@mixin text-2xs {
  font-size: 12px;
  line-height: 14px;
  font-family: var(--default-font-family);
  @include font-normal;
}

@mixin text-3xs {
  font-size: 8px;
  line-height: 10px;
  font-family: var(--default-font-family);
  @include font-normal
}

@mixin text-sm {
  font-size: 14px;
  line-height: 16px;
  font-family: var(--default-font-family);
  @include font-normal
}

@mixin text-md {
  font-size: 18px;
  line-height: 21px;
  font-family: var(--default-font-family);
  @include font-normal
}

@mixin text-md-bold {
  font-size: 18px;
  line-height: 21px;
  font-family: var(--default-font-family);
  @include font-extra-bold;
}

@mixin heading-3 {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--default-font-family);
  @include font-medium;
}

@mixin heading-4 {
  font-size: 20px;
  line-height: 28px;
  font-family: var(--default-font-family);
  @include font-medium;
}