@import "../../styles/mixins/global.scss";

.progressbar-container {
  margin-top: 10px;
  background: #efefef !important;
  border-radius: 10px !important;
}
.progressbar-progress {
  background: #5B5B5E !important;
  border-radius: 10px !important;
}

.recharts-wrapper{
  width: 100% !important; 
}
.gird-imemss2{
  // @media(max-width: 1440px){
  //   width: 96%;
  // }
  
    width: 100%;


  .recharts-surface{
    @media (max-width: 1440px) {
      width: 100%;
    }
  }




}
.courses-section {
  padding: 101px 0;
  .grids {
    @include layout-grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
    .grid-itemss {
      border-radius: 10px;
      background-color: #fff;
      padding: 20px;

      button {
        padding: 10px 30px;
        background-color: var(--colors-orange);
        @include pointer;
        color: var(--colors-white);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        border: none;
        @include text-md;
        line-height: 20px;
        @include font-medium;
        border-radius: 999px;
      }
      h1 {
        @include heading-3;
        color: var(--colors-black-1);
        margin-bottom: 0px;
        padding-bottom: 0;
      }
      p {
        @include text-xs;
        padding: 8px 0 0 0;
        line-height: 18px;
        margin: 0 0 15px 0;
        @include font-medium;
      }
    }

    .grid-itemss1{
      // width: 574px;
      margin-right: 30.34px;
      @media(max-width: 1280px){
        margin-right: 0;
      }
    }

    .grid-itemss3{

      .recharts-surface{
        width: 90%;

        @media(max-width: 1280px){
          width: 100%;
        }
      }
     
    
    }
  }
  .page-title2 {
    padding: 0 0 23px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      @include heading-3;
      color: var(--colors-black-1);
      margin: 0 0 0;
      padding: 0;
    }
    p {
      max-width: 928px;
      @include text-sm;
      line-height: 20px;
      @include font-medium;
      color: var(--colors-black-3);
    }
  }
  @media(max-width: 767px){
    padding-top: 26px;
  }
}

.lektion {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  padding-bottom: 23px;
  @media(max-width: 991px){
    font-size: 14px;
}
}
.count {
  width: 40px;
  margin-right: 20px;
  height: 40px;
  line-height: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--colors-orange);
  justify-content: center;
  border-radius: 10px;
  box-shadow: var(--s2);
  font-size: 18px;
  line-height: 21px;
  font-family: var(--default-font-family);
  font-weight: var(--font-normal);
  color: var(--colors-white);
}
.spce {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  font-size: large;
}
.mb-5 {
  margin-bottom: 20px;
}
.btns {
  padding: 8px 15px;
  background-color: var(--colors-orange);
  cursor: pointer;
  color: var(--colors-white);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
  border: none;
  font-size: 18px;
  line-height: 21px;
  font-family: var(--default-font-family);

  font-weight: var(--font-medium);
  border-radius: 999px;

  @media(max-width: 991px){
    padding: 12px 16px;
    font-size: 14px;
  }
}

.rotate-text {
  font-size: large;
  transform: rotate(90deg);
}

// .cartsection{
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
// }

.recharts-wrapper{
  width: 90%;
}

.recharts-surface{
  @media (max-width: 1440px) {
    width: 80%;
  }
}


.grap{
  display: grid;
  // display: flex;
  grid-template-columns: repeat( auto-fit, minmax(460px, 1fr) );
  gap: 30px;
  .grid-itemss1 {
    width: 100% !important;
  }
  .recharts-surface {
    width: 90%;
  }


}
.cartSection{
   position: relative;

   .cartLeft{
     position: absolute;
     top: 25%;
    transform: rotate(-180deg);
     writing-mode: vertical-lr;
   }

   .cartBottom{
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
   }

}

.modalSection{
  padding-right: 20px;
    .modal{
      max-width: 500px;
      background-color: var(--colors-white);
      min-width: 500px;

      border-radius: .42rem;
      position: fixed;
      top: 3%;
      left: 50%; 
      transform: translateX(-50%);
      
      // @media(max-width: 576px){
      //   width: 100%;
      // }
    }
  
    .modalHeader{
      padding: 1.5rem 1.75rem;
      border-bottom: 1px solid #ebedf3;
    }
  
    .modal-body{
      padding: 1.75rem;
    }
  
    .modal-dialog{
      margin: 10px;
    }
  
    .modalFooter{
      padding: 1.5rem;
    }
  }
  
  .formInput{
    width: 100%; 
    border: 1px solid rgb(204, 204, 204);
    min-height: 38px;
    border-radius: 4px;
    margin-top: 20px; 
    outline: none;
    padding: 2px 8px;
  }
  
  .modalSection{
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left:0;
  }
  
  .modal{
   
  }
  
  .modal-footer{
     display: flex;
     justify-content: flex-end;
    border-top: 1px solid #ebedf3;
    margin-top: 20px;
    padding: 1.5rem;
  }
  
  .btn_secondary , .btn_warning{
    border-radius: .42rem;
    border: none;
    padding: .65rem 1rem;
    cursor: pointer;
  }
  
  .btn_warning{
    margin-right: 0.5rem!important;
    color: var(--colors-white);
  
      background-color: #ffa800;
      border-color: #ffa800;
      border-radius: .42rem;
      border: none;
  
      &:hover{
        background-color: #ee9d01;
        border-color: #ee9d01;
      }
  }
  
  .btn_secondary {
    color: #3f4254;
    background-color: #e4e6ef;
    border-color: #e4e6ef;
  
    &:hover{
      color: #3f4254;
      background-color: #d7dae7;
      border-color: #d7dae7;
    }
  }